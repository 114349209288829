.round-22 {
    border-radius: 22px !important;
}

.round-l-22 {
    border-top-left-radius: 22px !important;
    border-bottom-left-radius: 22px !important;
}

.round-r-22 {
    border-top-right-radius: 22px !important;
    border-bottom-right-radius: 22px !important;
}

button:hover,
.h-effect-2:hover {
    transform: scale(1.1) !important;
}

.pointer,
.h-effect,
.h-effect-2,
.h-effect-3,
button {
    transition: 0.4s !important;
}
.no-drop{
    cursor: no-drop !important;
}

.cursor:hover {
    cursor: pointer !important;
}

.pointer:hover,
.h-effect:hover {
    transform: scale(1.01) !important;
}

.h-effect-3:hover {
    transform: scale(1.5) !important;
}

.small_button {
    padding: 4px 11px 4px 11px !important;
}

.card,
.bg-card,
.radius-20 {
    border-radius: 20px !important;

}

.card {
    box-shadow: 0px 0px 13px -11px rgba(0, 0, 0, 0.5) !important;
}

.no-radius {
    border-radius: 0px !important;
}

.card-body {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.card-header {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}

.btn-primary {
    border: 0 !important;
}

.table-striped-custom tbody tr:nth-of-type(even) {
    background-color: rgba(225, 222, 222, 0.29)
}


/* .pdf-viewer>canvas{
    width: 451px !important;
    height: 638px !important;
} */
.document-modal>.modal-dialog>.modal-content {
    background-color: transparent !important;
    border: 0 !important;
}

.bg-none {
    background-color: transparent !important;
}

.shadow {
    box-shadow:
        0px 0px 0.7px rgba(0, 0, 0, 0.012),
        0px 0px 1.7px rgba(0, 0, 0, 0.018),
        0px 0px 3.5px rgba(0, 0, 0, 0.022),
        0px 0px 7.3px rgba(0, 0, 0, 0.028),
        0px 0px 20px rgba(0, 0, 0, 0.04);
}

.multi-select-item-default-color {
    background-color: #F4F4F4 !important;
}

.multi-select-item {
    /* background-color: #F4F4F4 ; */
    color: black !important;
    font-size: 12px !important;
    border: 0 !important;
    transition: 0s !important;
}

.multi-select-item:hover {
    /* color: white !important; */
    background-color: #efe7d8 !important;
}

.list-group {
    border-radius: 8px !important;
    box-shadow:
        0px 0px 0.7px rgba(0, 0, 0, 0.012),
        0px 0px 1.7px rgba(0, 0, 0, 0.018),
        0px 0px 3.5px rgba(0, 0, 0, 0.022),
        0px 0px 7.3px rgba(0, 0, 0, 0.028),
        0px 0px 20px rgba(0, 0, 0, 0.04);
}

.overflow-visible{
    overflow: visible !important;
}

.position-static{
    position: static !important;
}

.no-pointer{
    cursor: initial !important;
}