@font-face {
    font-family: "Gilroy-SemiBold";
    src: url("../fonts/Gilroy-SemiBold.otf") format("opentype");
}

@font-face {
    font-family: "Gilroy-Regular";
    src: url("../fonts/Gilroy-Regular.otf") format("opentype");
}

@font-face {
    font-family: "Gilroy-Light";
    src: url("../fonts/Gilroy-Light.otf") format("opentype");
}

@font-face {
    font-family: "FA-Regular";
    src: url("../fonts/FA-Regular-400.otf") format("opentype");
}



h1,h2,h3,h4,h5,h6{
    font-family: 'Gilroy-SemiBold' !important;
    color:black !important;
}

.black,a,label{
    color:black ;
}

body,
div,
td,
a,
label,
input,
select
th{
    font-family: 'Gilroy-Regular' !important;
}
.regular,button{
    font-family: 'Gilroy-Regular' !important;
    color:black ;
}
.bold{
    font-family: 'Gilroy-SemiBold' !important;
}
.text-muted{
    color:#888888 !important;
}


